import { CurrentEventIdUpdate, SportType } from "../types";

export async function getCurrentEventId(
  sport: string
): Promise<CurrentEventIdUpdate | null> {
  if (sport !== SportType.Ufc) {
    //? not supported
    return null;
  }

  try {
    const response = await fetch(
      `https://dixgwmlrk5btxsfzviip6hdhrm0tazmw.lambda-url.eu-west-1.on.aws/`
    );

    return await response.json();
  } catch (error) {
    return null;
  }
}
