import { createBetlinkUrl, createEventCentreUrl } from "../../domain";
import {
  EventCentreConstructorArgs,
  EventIdType,
  MessageTopics,
} from "../../types";
import { isValidEventCentreConstructorOptions } from "../../utils";
import { getCurrentEventId } from "../../utils/currentEventId";

import { IframeContainer } from "./IframeContainer";

export class EventCentreIframe extends IframeContainer {
  constructor(args: EventCentreConstructorArgs) {
    isValidEventCentreConstructorOptions(args);

    super({
      __debug__: !!args.__debug__,
      targetElementSelector: args.targetElementSelector || "",
    });

    if (args.betlinkVersion) {
      this.initCommunication(createBetlinkUrl(args), "betlink");
    } else {
      this.initCommunication(createEventCentreUrl(args) || "", "event-centre");
    }
  }
}

export async function getAndSendCurrentEventId(
  ec: EventCentreIframe,
  options: EventCentreConstructorArgs
) {
  const currentEventId = await getCurrentEventId(options.sport);

  if (currentEventId) {
    ec.emit(MessageTopics.CURRENT_EVENT_ID_UPDATE, currentEventId);
  }
}

async function postInitProcess(
  ec: EventCentreIframe,
  options: EventCentreConstructorArgs
) {
  if (options.eventId === EventIdType.Current) {
    await getAndSendCurrentEventId(ec, options);
  }
}

export default function eventCentre(
  options: EventCentreConstructorArgs
): EventCentreIframe {
  const ec = new EventCentreIframe(options);
  postInitProcess(ec, options);

  return ec;
}
